<template>
  <!-- 车型分类管理 -->
  <div class="vehicleTypeManage">
    <el-button
      v-if="listFind('添加')"
      type="primary"
      size="small"
      @click="
        () => {
          this.show = true;
          this.headerText = '添加';
          this.popupStatus = 'add';
        }
      "
      >添加</el-button
    >
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              v-if="listFind('编辑')"
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow)"
              >编辑</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('查看')"
              type="text"
              size="small"
              sort="primary"
              @click="seeFun(scope.scopeRow)"
              >查看</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('删除')"
              type="text"
              size="small"
              sort="danger"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      :header-text="headerText"
      :disabled="disabled"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="车型：" prop="vehicleTypeDictCode">
          <el-select
            v-model="Form.vehicleTypeDictCode"
            :disabled="disabled"
            placeholder="请选择"
            @change="Form1TypeChange"
          >
            <el-option
              v-for="items in carTypeList"
              :key="items.dictCode"
              :label="items.dictValue"
              :value="items.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子类：" prop="vehicleTypeChildName">
          <el-input
            v-model="Form.vehicleTypeChildName"
            maxlength="10"
            :disabled="
              disabled == true ? true : childDisabled == true ? true : false
            "
            placeholder="请输入子类"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否为巴士：">
          <el-input v-model="isBSX" disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="分类描述：" prop="modelTypeDescription">
          <el-input
            v-model="Form.modelTypeDescription"
            maxlength="30"
            :disabled="disabled"
            placeholder="请输入分类描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务类型：" prop="operationMode">
          <el-select
            v-model="Form.operationMode"
            :disabled="disabled"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="items in carClassList"
              :key="items.value"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="座位数：" prop="seatNum">
          <el-input
            v-model="Form.seatNum"
            :disabled="disabled"
            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
            placeholder="请输入座位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="行李数：" prop="luggageNum">
          <el-input
            v-model="Form.luggageNum"
            :disabled="disabled"
            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
            placeholder="请输入行李数"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片：" prop="Form1IMG">
          <el-upload
            ref="elUpload"
            :disabled="disabled"
            action
            list-type="picture-card"
            class="upload-demo"
            :auto-upload="false"
            :multiple="false"
            :on-change="imageSuccessFun"
            :on-remove="imageListRemove"
            :file-list="fileList"
            :limit="2"
            :class="{ hide: disabled }"
          >
            <i class="el-icon-plus"></i>
            <div v-if="!disabled" slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过240kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-switch
            v-model="switchOnoff"
            :disabled="disabled"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
            @change="Form1Switch"
          ></el-switch>
        </el-form-item>
      </el-form>
    </charterPopup>
    <!-- <el-dialog :visible.sync="dialogVisible" center top="20vh">
      <img width="50%" :src="dialogImageUrl" alt />
    </el-dialog>-->
    <div
      v-show="dialogVisible"
      class="imgPopup"
      @click="() => (dialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import {
  carTypeListAPI,
  renderCarTypeListAPI,
  carTypeDeleteAPI,
  carTypeUpdateAPI,
  addCarTypeListAPI,
  uploadFileAPI,
} from "@/api/lib/api.js";
import host from "@/api/static/host.js";
export default {
  watch: {
    Form: {
      handler(val) {
        if (!!val.vehicleTypeDictCode) {
          if (val.vehicleTypeDictCode == "BSX") {
            this.isBSX = "是";
          } else {
            this.isBSX = "否";
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      carClassList: [
        {
          label: "租车",
          value: 5,
        },
        {
          label: "包车",
          value: 4,
        },
      ],
      childDisabled: true,
      disabled: false,
      popupStatus: "",
      fileList: [], //上传文件列表
      switchOnoff: false, //是否启用开关
      isBSX: "", //是否巴士
      //   规则校验
      rules: {
        operationMode: [
          { required: true, message: "请选择业务类型", trigger: "change" },
        ],
        vehicleTypeDictCode: [
          { required: true, message: "请选择车辆类型", trigger: "change" },
        ],
        vehicleTypeChildName: [
          { required: true, message: "请输入子类", trigger: "change" },
        ],
        modelTypeDescription: [
          { required: true, message: "请输入分类描述", trigger: "blur" },
        ],
        seatNum: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value == "") {
                callback("请输入座位数");
              } else {
                if (value > 60) {
                  callback("座位数最大不得超过60");
                } else {
                  callback();
                }
              }
            },
          },
        ],
        luggageNum: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value == "") {
                callback("请输入行李数");
              } else {
                if (value > 40) {
                  callback("行李数最大不得超过40");
                } else {
                  callback();
                }
              }
            },
          },
        ],
        Form1IMG: [
          {
            required: true,
            validator: this.imgRules,
            trigger: "blur",
          },
        ],
      },
      imgBase64: "",
      imgMsg: "",
      imgSize: 0,
      Form: {
        operationMode: [], //业务类型
        vehicleTypeDictCode: "", //车型
        vehicleTypeName: "", //车型名称
        vehicleTypeChildName: "", //子类
        modelTypeDescription: "", //分类描述
        seatNum: "", //座位数
        luggageNum: "", //行李数
        picture: "", //图片
        status: "1", //是否启用
      },
      headerText: "",
      dialogVisible: false,
      dialogImageUrl: "",
      carTypeList: [],
      show: false, // 弹窗if
      tableData: [],
      titleName: [
        {
          title: "车型分类名称",
          props: "vehicleTypeName",
          width: "160",
        },
        {
          title: "子类",
          props: "vehicleTypeChildName",
        },
        {
          title: "分类描述",
          props: "modelTypeDescription",
        },
        {
          title: "座位数",
          props: "seatNum",
        },
        {
          title: "行李数",
          props: "luggageNum",
        },
        {
          title: "图片",
          props: "picture",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: () => {
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.picture;
                },
              },
            });
          },
        },
        {
          title: "业务类型",
          props: "operationMode",
          SpecialJudgment: (res) => {
            let txt = "";
            res.map((item) => {
              if (item == 4) {
                txt += "包车、";
              } else if (item == 5) {
                txt += "租车、";
              }
            });
            return txt.substr(0, txt.length - 1);
          },
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  console.log(params.status);
                  let obj = {
                    id: params.id,
                    luggageNum: params.luggageNum,
                    modelTypeDescription: params.modelTypeDescription,
                    picture: params.picture,
                    seatNum: params.seatNum,
                    status: params.status == 1 ? 0 : 1,
                    vehicleTypeChildName: params.vehicleTypeChildName,
                    vehicleTypeDictCode: params.vehicleTypeDictCode,
                    vehicleTypeName: params.vehicleTypeName,
                  };
                  this.updateDateFun(obj);
                },
              },
            });
          },
        },
      ],
    };
  },
  methods: {
    // 点击查看按钮
    seeFun({
      vehicleTypeDictCode,
      vehicleTypeChildName,
      modelTypeDescription,
      seatNum,
      luggageNum,
      picture,
      status,
      id,
      operationMode,
    }) {
      this.headerText = "查看";
      this.disabled = true;
      this.show = true;
      this.Form = {
        vehicleTypeDictCode,
        vehicleTypeChildName,
        modelTypeDescription,
        seatNum,
        luggageNum,
        status,
        picture,
        id,
        operationMode,
      };
      this.fileList = [{ url: host.imgURL + picture }];
      if (status === 0) {
        this.switchOnoff = true;
      } else {
        this.switchOnoff = false;
      }
    },
    // 点击编辑按钮
    updateBtn({
      vehicleTypeDictCode,
      vehicleTypeChildName,
      modelTypeDescription,
      seatNum,
      luggageNum,
      picture,
      status,
      id,
      operationMode,
    }) {
      if (vehicleTypeDictCode == "BSX") {
        this.childDisabled = false;
        this.carClassList = [
          {
            label: "包车",
            value: 4,
          },
        ];
      } else {
        this.carClassList = [
          {
            label: "租车",
            value: 5,
          },
          {
            label: "包车",
            value: 4,
          },
        ];
      }
      this.headerText = "编辑";
      this.popupStatus = "update";
      this.disabled = false;
      this.show = true;
      this.Form = {
        vehicleTypeDictCode,
        vehicleTypeChildName,
        modelTypeDescription,
        seatNum,
        luggageNum,
        status,
        picture,
        id,
        operationMode,
      };
      this.fileList = [{ url: host.imgURL + picture }];
      if (status === 0) {
        this.switchOnoff = true;
      } else {
        this.switchOnoff = false;
      }
    },
    // 清空
    clearForm() {
      this.childDisabled = true;
      this.disabled = false;
      this.show = false;
      this.switchOnoff = false;
      this.fileList = [];
      this.isBSX = "";
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = 0;
      this.Form = {
        operationMode: [], //业务类型
        vehicleTypeDictCode: "", //车型
        vehicleTypeName: "", //车型名称
        vehicleTypeChildName: "", //子类
        modelTypeDescription: "", //分类描述
        seatNum: "", //座位数
        luggageNum: "", //行李数
        picture: "", //图片
        status: "1", //是否启用
      };
    },
    // 确定按钮回调
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          switch (this.popupStatus) {
            case "add":
              this.addDataFun();
              break;
            case "update":
              this.updateDataFun();
              break;
          }
        }
      });
    },
    // 编辑修改数据方法
    updateDataFun() {
      new Promise((res, rej) => {
        if (this.fileList[0].size != undefined) {
          // -----------------先上传图片-------------------
          // FormData 对象
          let form = new FormData();
          // 文件对象
          form.append("files", this.fileList[0].raw);
          uploadFileAPI(form).then((params) => {
            if (params.code == "SUCCESS") {
              this.Form.picture = params.data[0];
              res(this.Form);
            }
          });
        } else {
          res(this.Form);
        }
      }).then((rs) => {
        this.updateDateFun(rs);
      });
    },
    // 添加数据方法
    addDataFun() {
      // -----------------先上传图片-------------------
      // FormData 对象
      let form = new FormData();
      // 文件对象
      form.append("files", this.fileList[0].raw);
      console.log(this.fileList);
      new Promise((resolve, reject) => {
        uploadFileAPI(form).then((res) => {
          console.log(res);
          if (res.code == "SUCCESS") {
            resolve(res.data);
          }
        });
      }).then((jump) => {
        console.log(jump);
        this.Form.picture = jump[0];
        addCarTypeListAPI(this.Form).then((res) => {
          console.log(res);
          if (res.code == "SUCCESS") {
            this.$message.success("添加成功");
            this.renderTable();
            this.clearForm();
          }
        });
      });
    },
    // 车型下拉框change事件
    Form1TypeChange(val) {
      this.Form.vehicleTypeChildName = "";
      if (val == "BSX") {
        this.childDisabled = false;
        this.carClassList = [
          {
            label: "包车",
            value: 4,
          },
        ];
      } else {
        this.carClassList = [
          {
            label: "租车",
            value: 5,
          },
          {
            label: "包车",
            value: 4,
          },
        ];
        this.childDisabled = true;
        this.carTypeList.map((item) => {
          if (item.dictCode == val) {
            this.Form.vehicleTypeChildName = item.dictValue;
          }
        });
      }
      const label =
        this.carTypeList.find((item) => item.dictCode === val) || {};
      this.Form.vehicleTypeName = label.dictValue;
      this.Form.vehicleTypeDictCode = label.dictCode;
    },
    //   开关change事件
    Form1Switch(val) {
      if (val) {
        this.Form.status = 0;
      } else {
        this.Form.status = 1;
      }
    },
    // 移除图片事件
    imageListRemove(file, fileList) {
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = "";
      this.fileList = [];
      this.$refs.Form.clearValidate("Form1IMG");
    },
    // 自定义图片规则
    imgRules(rule, value, callback) {
      if (this.imgBase64 == "") {
        if (this.fileList.length > 0 && this.fileList[0].size == undefined) {
          callback();
        } else {
          callback("请上传图片！");
        }
      } else {
        if (this.imgMsg === "jpg" || this.imgMsg === "png") {
          if (this.imgSize < 245760) {
            callback();
          } else {
            callback("图片大小超过限制！");
          }
        } else {
          callback("上传文件格式不正确！");
        }
      }
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun(file, fileList) {
      this.fileList = [file];
      let that = this;
      this.imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.imgSize = file.size;
      var a = new FileReader();
      a.readAsDataURL(file.raw); //读取文件保存在result中
      a.onload = function (e) {
        that.imgBase64 = e.target.result; //读取的结果在result中
        that.$refs.Form.validateField("Form1IMG");
      };
    },
    // 删除数据
    deleteFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          carTypeDeleteAPI({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.renderTable();
            }
          });
        })
        .catch(() => {});
    },
    // 修改数据
    updateDateFun(params) {
      carTypeUpdateAPI(params).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.renderTable();
          this.clearForm();
        }
      });
    },
    // 表格数据渲染
    renderTable() {
      renderCarTypeListAPI().then((res) => {
        console.log(res);
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
          console.log(this.tableData[1]);
        }
      });
    },
  },
  mounted() {
    this.renderTable();
    // 获取车型列表数据
    carTypeListAPI().then((res) => {
      console.log(res);
      if (res.code == "SUCCESS") {
        this.carTypeList = res.data;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.imgPopup {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  img {
    max-width: 70%;
    max-height: 70%;
  }
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/deep/.el-dialog__body {
  text-align: center !important;
}
/deep/.el-upload-list__item-status-label {
  display: none !important;
}
/deep/.el-dialog__header {
  padding: 26px;
}
.vehicleTypeManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
}
.Table {
  margin-top: 14px;
}
</style>
